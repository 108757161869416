@use "sass:color"; @use "sass:math"; @use "@/web/client/assets/css/variables" as *; 
footer{
    background: $dark;
    color: $text-on-dark;
    padding: $padding * 2;

    font-size: 1rem;
    line-height: $footer-line-height;
    text-align: center;
}
